import { PrintBuffer } from './printbuffer';
import APIAdapter from "./api_adapter";
import { DateTime } from "luxon";

export class Printer {
    private readonly device: USBDevice
    private static readonly INIT_PRINT: Uint8Array = Uint8Array.from([0x1B, 0x40])
    private static readonly LINE_FEED: Uint8Array = Uint8Array.from([0x0A])
    private static readonly CUT_PAPER: Uint8Array = Uint8Array.from([0x1d, 0x56, 0x41, 0x30])
    private static readonly END_SESSION: Uint8Array = Uint8Array.from([0xFA])
    private static readonly ALIGN_CENTER: Uint8Array = Uint8Array.from([0x1B, 0x61, 0x01])
    private static readonly FONT_A: Uint8Array = Uint8Array.from([0x1B, 0x4D, 0x00])
    private static readonly QR_CODE_MODEL: Uint8Array = Uint8Array.from([0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, 0x32, 0x00])
    private static readonly QR_CODE_SIZE: Uint8Array = Uint8Array.from([0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x08])
    private static readonly QR_CODE_ERROR_CORRECTION: Uint8Array = Uint8Array.from([0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x31])
    private static readonly PRINT_QR_CODE: Uint8Array = Uint8Array.from([0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x51, 0x30])
    private static readonly ISO_8859_2_ENCODING: Uint8Array = Uint8Array.from([0x1B, 0x74, 0x27])
    private static readonly ISO_8559_2_CODE_POINTS = {
        "128": 0,
        "129": 1,
        "130": 2,
        "131": 3,
        "132": 4,
        "133": 5,
        "134": 6,
        "135": 7,
        "136": 8,
        "137": 9,
        "138": 10,
        "139": 11,
        "140": 12,
        "141": 13,
        "142": 14,
        "143": 15,
        "144": 16,
        "145": 17,
        "146": 18,
        "147": 19,
        "148": 20,
        "149": 21,
        "150": 22,
        "151": 23,
        "152": 24,
        "153": 25,
        "154": 26,
        "155": 27,
        "156": 28,
        "157": 29,
        "158": 30,
        "159": 31,
        "160": 32,
        "164": 36,
        "167": 39,
        "168": 40,
        "173": 45,
        "176": 48,
        "180": 52,
        "184": 56,
        "193": 65,
        "194": 66,
        "196": 68,
        "199": 71,
        "201": 73,
        "203": 75,
        "205": 77,
        "206": 78,
        "211": 83,
        "212": 84,
        "214": 86,
        "215": 87,
        "218": 90,
        "220": 92,
        "221": 93,
        "223": 95,
        "225": 97,
        "226": 98,
        "228": 100,
        "231": 103,
        "233": 105,
        "235": 107,
        "237": 109,
        "238": 110,
        "243": 115,
        "244": 116,
        "246": 118,
        "247": 119,
        "250": 122,
        "252": 124,
        "253": 125,
        "258": 67,
        "259": 99,
        "260": 33,
        "261": 49,
        "262": 70,
        "263": 102,
        "268": 72,
        "269": 104,
        "270": 79,
        "271": 111,
        "272": 80,
        "273": 112,
        "280": 74,
        "281": 106,
        "282": 76,
        "283": 108,
        "313": 69,
        "314": 101,
        "317": 37,
        "318": 53,
        "321": 35,
        "322": 51,
        "323": 81,
        "324": 113,
        "327": 82,
        "328": 114,
        "336": 85,
        "337": 117,
        "340": 64,
        "341": 96,
        "344": 88,
        "345": 120,
        "346": 38,
        "347": 54,
        "350": 42,
        "351": 58,
        "352": 41,
        "353": 57,
        "354": 94,
        "355": 126,
        "356": 43,
        "357": 59,
        "366": 89,
        "367": 121,
        "368": 91,
        "369": 123,
        "377": 44,
        "378": 60,
        "379": 47,
        "380": 63,
        "381": 46,
        "382": 62,
        "711": 55,
        "728": 34,
        "729": 127,
        "731": 50,
        "733": 61
    }
    private readonly subtext: string

    constructor(device: USBDevice, apiAdapter: APIAdapter, subtext: string) {
        this.device = device
        this.subtext = subtext
    }

    public async printTicket(printJob: PrintJob): Promise<Boolean> {
        let printBuffer = this.buildPrintBuffer(this.subtext, printJob.display_string, printJob.qr_code_payload);
        return await this.print(printBuffer)
    }

    private buildPrintBuffer(subText: string, number: string, qrPayload: string): Uint8Array {
        let buffer = new PrintBuffer()
        // Clean up any possible previous commands that are still in the printer's buffer
        buffer.add(Printer.END_SESSION)

        // Start session
        buffer.add(Printer.INIT_PRINT)
        // Use ISO-8850-2 Encoding because ASCII cannot display Umlauts.
        buffer.add(Printer.ISO_8859_2_ENCODING)
        buffer.add(Printer.ALIGN_CENTER)
        buffer.add(this.fontSize(2, 1))
        buffer.add(Printer.FONT_A)
        buffer.add(this.writeLn('wartenummer.at'))

        // QR code
        buffer.add(Printer.QR_CODE_MODEL)
        buffer.add(Printer.QR_CODE_SIZE)
        buffer.add(Printer.QR_CODE_ERROR_CORRECTION)
        let s = qrPayload.length + 3
        let pL = this.toBytesInt8(s % 256)
        let pH = this.toBytesInt8(Math.floor(s / 256))
        buffer.add(new Uint8Array([0x1d, 0x28, 0x6b, ...pL, ...pH, 0x31, 0x50, 0x30]))
        buffer.add(this.stringToBytesInt8(qrPayload))
        buffer.add(Printer.PRINT_QR_CODE)

        // Date
        let date:string = DateTime.now().setZone("Europe/Berlin").toFormat("d'.'L'.'yyyy")
        buffer.add(this.emphasize())
        buffer.add(this.smooth())
        buffer.add(this.fontSize(1, 1))
        buffer.add(this.writeLn(date))

        buffer.add(Printer.LINE_FEED)
        buffer.add(Printer.LINE_FEED)

        // Ticket number
        buffer.add(this.fontSize(7,7))
        buffer.add(this.writeLn(number))
        buffer.add(Printer.LINE_FEED)

        // Subtext
        buffer.add(this.fontSize(2, 1))

        buffer.add(this.writeLn(subText))

        // Finish
        buffer.add(Printer.CUT_PAPER)
        buffer.add(Printer.END_SESSION)

        return buffer.toInt8Array()
    }

    private smooth(): Uint8Array {
        return new Uint8Array([0x1D, 0x62, 0x01])
    }

    private emphasize(): Uint8Array {
        return new Uint8Array([0x1b, 0x47, 0x01])
    }

    private async print(data: Uint8Array): Promise<boolean> {
      let result = await this.device.transferOut(1, data);
      return result.status == 'ok'
    }

    // https://escpos.readthedocs.io/en/latest/font_cmds.html#d21
    private fontSize(width: number, height: number): Uint8Array {
        if (width > 0 && width < 9 && height > 0 && height < 9) {
            let sum = ((width - 1) * 16) + ((height - 1))
            return new Uint8Array([0x1D, 0x21, sum])
        } else
        {
            throw 'invalid font size'
        }
    }

    private writeLn(line: string): Uint8Array {
        return this.encodeISO_8559_2(`${line}\n`)
    }

    private stringToBytesInt8(string: string): Uint8Array {
        let result = new Uint8Array(0);
        for(let i = 0; i < string.length; i++){
            let number = string.charCodeAt(i);
            result = new Uint8Array([...result, ...this.toBytesInt8(number)]);
        }
        return result
    }

    private encodeISO_8559_2(string: string): Uint8Array {
        let buffer = new PrintBuffer()
        let length = string.length;
        let index = -1;
        let codePoint;
        let pointer;
        while (++index < length) {
            codePoint = string.charCodeAt(index)
            // ASCII encoding from charCodeAt() matches ISO-8559-2 here
            if (codePoint >= 0x00 && codePoint <= 0x7F) {
                buffer.add(new Uint8Array([codePoint]));
                continue
            }
            pointer = Printer.ISO_8559_2_CODE_POINTS[codePoint.toString()]
            buffer.add(new Uint8Array([pointer + 0x80]))
        }
        return buffer.toInt8Array()
    }

    private toBytesInt8(num: number): Uint8Array {
       return new Uint8Array([num])
    }

}
