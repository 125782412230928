import {Printer} from "./printer";
import {PrintReporter} from "./print_reporter"

export default class PrintQueue {
  private queue: Array<PrintJob> = [];
  private readonly history: Array<PrintJob> = [];
  private readonly printer: Printer
  private readonly printReporter: PrintReporter

  constructor(printer: Printer, printReporter: PrintReporter) {
    this.printer = printer;
    this.printReporter = printReporter;
  }

  async add(print_jobs: Array<PrintJob>) {
    if (print_jobs.length == 0) {
      return;
    }
    // @ts-ignore
    await navigator.locks.request("queue_operation", async lock => {
      const new_jobs = print_jobs.filter(print_job => {
        return !this.jobPresent(this.queue, print_job.print_job_id);
      });
      this.queue = this.queue.concat(new_jobs);
    });
    await this.print();
  }

  private async print() {
    // @ts-ignore
    await navigator.locks.request("queue_operation", async lock => {
      const to_delete: Array<number> = [];
      this.queue.forEach((print_job, i) => {
        // Race condition check - Did we already print this job?
        if (this.jobPresent(this.history, print_job.print_job_id)) {
          console.log("In History - Marking for deletion")
          to_delete.unshift(i);
        } else if (this.printer.printTicket(print_job)) {
          this.printReporter.reportPrinted(print_job);
          to_delete.unshift(i);
          this.successful_print(print_job);
        }
      });
      this.delete_from_queue(to_delete);
    });
  }

  private delete_from_queue(reverse_sorted_index: Array<number>) {
    console.log("Deleting from queue")
    reverse_sorted_index.forEach(queue_index => {
      this.queue.splice(queue_index, 1);
    });
  }

  private successful_print(print_job: PrintJob) {
    console.log("Putting into History");
    this.history.push(print_job);
  }

  private jobPresent(array: Array<PrintJob>, id: String): boolean {
    return array.some(item => {
      return item.print_job_id === id;
    });
  }
}
