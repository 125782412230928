import axios, {AxiosInstance} from "axios";
import axiosRetry from 'axios-retry';
import "./types/PrintJob";

export default class APIAdapter {
  private readonly  axios: AxiosInstance;

  constructor(authenticationToken: string, baseURL: string) {
    this.axios = axios.create({
      baseURL: baseURL,
      timeout: 2000,
      headers: {
        'wnr-client': 'printer',
        'wnr-api-token': authenticationToken
      }
    })
    // axiosRetry(this.axios, { retries: 0 });
  }

  async getTicketsToPrint(): Promise<Array<PrintJob>> {
    const url = `print-jobs/pending`
    try {
      const response = await this.axios.get(url, {
        validateStatus: function(status) {
          return status == 200;
        }
      });
      return response.data;
    } catch {
      console.log("Error: Could not get printer tickets from server");
      throw Error;
    }
  }

  async reportPrinted(print_job: PrintJob, retry: Boolean = false): Promise<boolean> {
    const url = `print-jobs/${print_job.print_job_id}`
    const retries = retry ? 10 : 0;

    try {
      await this.axios.put(
        url,
        {
          state: 'printed'
        },
        {
          validateStatus: function(status) {
            return status == 200;
          },
          "axios-retry": {retries: retries}
        }
      );
      return true;
    } catch(error) {
      console.log(`Error while reporting print status: ${error}`);
      throw Error;
    }
  }
}
