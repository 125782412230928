import APIAdapter from "./api_adapter";
import PrintQueue from "./print_queue";
import { Printer } from './printer'
import {PrintReporter} from "./print_reporter";

export class PrinterService {
  private interval: NodeJS.Timer
  private readonly apiAdapter: APIAdapter
  private readonly printQueue: PrintQueue
  private readonly eventObserver: Function

  // The big picture: The PrinterService class is the main component that initializes everything else
  // The individual components communicate mostly via callbacks/observers: The API gets called from an interval,
  // any received tickets will be pushed in the PrintQueue object which then notifies the Printer. The Printer
  // will print the Ticket and then notify the PrintReport which will mark report the ticket as printed to the API and
  // remove it from the local PrintQueue.

  // Use this library from the outside by calling PrinterService.run().
  public static async run(authentication_token: string, device: USBDevice, eventObserver: Function, baseURL: string, ticketSubtext: string): Promise<PrinterService> {
    await device.open()
    await device.selectConfiguration(1);

    await device.claimInterface(
        device.configuration.interfaces[0].interfaceNumber
    );
    let apiAdapter = new APIAdapter(authentication_token, baseURL)
    let printReporter = new PrintReporter(apiAdapter)
    let printer = new Printer(device, apiAdapter, ticketSubtext)

    let printQueue = new PrintQueue(printer, printReporter)
    let printerService = new PrinterService(apiAdapter, printQueue, eventObserver)

    printerService.start()
    return printerService
  }

  constructor(apiAdapter: APIAdapter, printQueue: PrintQueue, eventObserver: Function) {
    this.apiAdapter = apiAdapter
    this.printQueue = printQueue
    this.eventObserver = eventObserver
  }

  public stop() {
    clearInterval(this.interval);
  }

  public start() {
    this.interval = setInterval((function(self) {
          return function() {self.get_print_jobs();}
    })(this),1500);
  }

  private async get_print_jobs() {
    try {
      const printJobs = await this.apiAdapter.getTicketsToPrint();
      await this.printQueue.add(printJobs);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }
}
