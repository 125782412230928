export class PrintBuffer {
  private buffer: Uint8Array = new Uint8Array([])

  public add(addition: Uint8Array) {
    this.buffer = new Uint8Array([...this.buffer, ...addition])
  }

  public toInt8Array(): Uint8Array {
    return this.buffer
  }
}