import APIAdapter from "./api_adapter";

export class PrintReporter {
  private readonly apiAdapter: APIAdapter

  constructor(apiAdapter: APIAdapter) {
     this.apiAdapter = apiAdapter
  }

  async reportPrinted(print_job: PrintJob): Promise<boolean> {
    return await this.apiAdapter.reportPrinted(print_job, true);
  }
}
